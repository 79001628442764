#about-header {
    /* margin-top: 200px;
    margin-bottom: 200px;
    color: rgb(26, 153, 141); */
}

#about-header h1 {
    color: rgb(26, 153, 141);
    font-size: 90px;
    margin-top: 100px;
    /* padding: 30px; */
    font-weight: 100;
    font-family: serif;
}

#about-header h2 {
    color: rgb(26, 153, 141);
    font-size: 30px;
    /* padding: 30px; */
    font-weight: 100;
    font-family: serif;
    margin-bottom: 200px;
}

#about-container .about-image-container {
    position: relative;
}

.about-photo-credit {
    position: absolute;
    bottom: 0;
    left: 0;
    color: white;
    font-size: x-small;
    width: 100%;
    margin-left: 10px;
}

.about-photo-credit * {
    color: white;
    font-size: x-small;
}

.about-image-container p {
    font-size: smaller;
}

#about-container p {
    text-align: left;
    font-size: larger;
}

.about-full-width-banner {
    width: 90vw;
    margin-left: 5vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.about-full-width-banner p {
    width: 50%;
}

.about-main-text {
    /* color: rgb(26, 153, 141); */
}

.about-full-width-banner img {
    width: 500px;
    height: 350px;
    object-fit: cover;
}

#about-page-bottom-text-one {
    color: rgb(26, 153, 141);
    font-size: 60px;
    margin-top: 100px;
    font-weight: 100;
    font-family: serif;
}

#about-page-bottom-text-two {
    color: rgb(26, 153, 141);
    font-size: 30px;
    margin-top: 100px;
    padding-bottom: 30px;
    font-weight: 100;
    font-family: serif;
}

#about-consult-button {
    background-color: rgb(26, 153, 141);
    color: white;
    font-size: large;
    border: none;
    padding: 20px;
    cursor: pointer;
    margin-bottom: 120px;
}

@media screen and (max-width: 768px) {
    #about-header {
        /* margin-top: 200px;
        margin-bottom: 200px;
        color: rgb(26, 153, 141); */
    }

    #about-header h1 {
        color: rgb(26, 153, 141);
        font-size: 90px;
        margin-top: 100px;
        /* padding: 30px; */
        font-weight: 100;
        font-family: serif;
    }

    #about-header h2 {
        color: rgb(26, 153, 141);
        font-size: 30px;
        /* padding: 30px; */
        font-weight: 100;
        font-family: serif;
        margin-bottom: 200px;
    }

    #about-container .about-image-container {
        position: relative;
        display: none;
    }

    .about-photo-credit {
        position: absolute;
        bottom: 0;
        left: 0;
        color: white;
        font-size: x-small;
        width: 100%;
        margin-left: 10px;
        display: none;
    }

    .about-photo-credit * {
        color: white;
        font-size: x-small;
        display: none;
    }

    .about-image-container p {
        font-size: smaller;
        display: none;
    }

    #about-container p {
        text-align: left;
        font-size: larger;
    }

    .about-full-width-banner {
        width: 90vw;
        margin-left: 5vw;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .about-full-width-banner p {
        width: 90%;
    }

    .about-main-text {
        /* color: rgb(26, 153, 141); */
        /* background-color: pink; */
    }

    .about-full-width-banner img {
        width: 500px;
        height: 350px;
        object-fit: cover;
    }

    #about-page-bottom-text-one {
        color: rgb(26, 153, 141);
        font-size: 60px;
        margin-top: 100px;
        font-weight: 100;
        font-family: serif;
    }

    #about-page-bottom-text-two {
        color: rgb(26, 153, 141);
        font-size: 30px;
        margin-top: 100px;
        padding-bottom: 30px;
        font-weight: 100;
        font-family: serif;
    }

    #about-consult-button {
        background-color: rgb(26, 153, 141);
        color: white;
        font-size: large;
        border: none;
        padding: 20px;
        cursor: pointer;
        margin-bottom: 120px;
    }
}