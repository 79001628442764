#services-header {
    /* margin-top: 200px; */
    margin-bottom: 200px;
    color: rgb(26, 153, 141);
}

#services-header h1 {
    color: rgb(26, 153, 141);
    font-size: 90px;
    margin-top: 100px;
    /* padding: 30px; */
    font-weight: 100;
    font-family: serif;
}

#services-header h2 {
    color: rgb(26, 153, 141);
    font-size: 30px;
    /* padding: 30px; */
    font-weight: 100;
    font-family: serif;
    margin-bottom: 200px;
}

.services-banner-container {
    display: flex;
    margin-bottom: 100px;
}

.services-banner-container .banner-left-side {
    width: 40%;
    /* background-color: pink; */
    color: rgb(26, 153, 141);
    font-family: serif;
}

.services-banner-container .banner-right-side {
    width: 60%;
    padding: 20px 100px 20px 60px;
    text-align: left;
    font-size: large;
    /* background-color: aqua; */
}

#services-page-bottom-text-one {
    color: rgb(26, 153, 141);
    font-size: 60px;
    margin-top: 100px;
    font-weight: 100;
    font-family: serif;
}

#services-page-bottom-text-two {
    color: rgb(26, 153, 141);
    font-size: 30px;
    margin-top: 100px;
    padding-bottom: 30px;
    font-weight: 100;
    font-family: serif;
}

#services-consult-button {
    background-color: rgb(26, 153, 141);
    color: white;
    font-size: large;
    border: none;
    padding: 20px;
    cursor: pointer;
    margin-bottom: 120px;
}

@media screen and (max-width: 768px) {
    #services-header {
        /* margin-top: 200px; */
        margin-bottom: 200px;
        color: rgb(26, 153, 141);
    }
    
    #services-header h1 {
        color: rgb(26, 153, 141);
        font-size: 90px;
        margin-top: 100px;
        /* padding: 30px; */
        font-weight: 100;
        font-family: serif;
    }

    #services-header h2 {
        color: rgb(26, 153, 141);
        font-size: 30px;
        /* padding: 30px; */
        font-weight: 100;
        font-family: serif;
        margin-bottom: 200px;
    }

    .services-banner-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
    }

    .services-banner-container .banner-left-side {
        width: 100%;
        /* background-color: pink; */
        color: rgb(26, 153, 141);
        font-family: serif;
    }

    .services-banner-container .banner-right-side {
        width: 90%;
        padding: 5%;
        text-align: left;
        font-size: large;
        /* background-color: aqua; */
    }

    #services-page-bottom-text-one {
        color: rgb(26, 153, 141);
        font-size: 60px;
        margin-top: 100px;
        font-weight: 100;
        font-family: serif;
    }

    #services-page-bottom-text-two {
        color: rgb(26, 153, 141);
        font-size: 30px;
        margin-top: 100px;
        padding-bottom: 30px;
        font-weight: 100;
        font-family: serif;
    }

    #services-consult-button {
        background-color: rgb(26, 153, 141);
        color: white;
        font-size: large;
        border: none;
        padding: 20px;
        cursor: pointer;
        margin-bottom: 120px;
    }
}