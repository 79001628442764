#events-header {
    /* margin-top: 200px; */
    margin-bottom: 200px;
    color: rgb(26, 153, 141);
}

#events-header h1 {
    color: rgb(26, 153, 141);
    font-size: 90px;
    margin-top: 100px;
    /* padding: 30px; */
    font-weight: 100;
    font-family: serif;
}

#events-header h2 {
    color: rgb(26, 153, 141);
    font-size: 30px;
    /* padding: 30px; */
    font-weight: 100;
    font-family: serif;
    margin-bottom: 200px;
}



