


#home-header #logo {
    margin-top: 40px;
    width: 60%;
}

#home-header #home-subtitle {
    color: rgb(26, 153, 141);
    font-size: 70px;
    margin-top: 40px;
    padding: 30px;
    font-weight: 100;
    font-family: serif;
}

/* #home-header #home-title{
    color: rgb(26, 153, 141);
    font-size: 50px;
    margin-top: 50px;
    margin-bottom: 80px;
    font-weight: 800;
    font-family: serif;
} */







#home-services-header {
    color: rgb(26, 153, 141);
    font-size: 60px;
    margin-top: 80px;
    font-family: serif;
}

#services-home-container {
    display: flex;
}

#services-home-container p {
    text-align: center;
}

.services-block {
    padding: 30px;
    margin-bottom: 40px;
    font-family: serif;
}

.services-block h4 {
    font-size: 25px;
    color: rgb(26, 153, 141);
}

.services-block p {
    font-size: 22px;
    text-align: left;
}

#home-learn-more-button {
    background-color: rgb(26, 153, 141);
    color: white;
    font-size: large;
    border: none;
    padding: 20px;
    cursor: pointer;
    margin-bottom: 120px;
}



.image-container {
    position: relative;
    width: 100%;
    max-width: 100vw;
    height: 300px;
    /* Set the desired height */
    overflow: hidden;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Crop the image to cover the container */
    display: block;
}

.home-photo-credit {
    position: absolute;
    bottom: 0;
    left: 0;
    color: white;
    font-size: x-small;
    width: 100%;
}

.home-photo-credit * {
    color: white;
    font-size: x-small;
}

.quote-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    width: 80%;
}

.quote-text {
    font-size: 25px;
    font-weight: bold;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
}

.quote-author {
    margin-top: 10px;
    font-size: 25px;
    font-style: italic;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
}


#home-page-bottom-text-one {
    color: rgb(26, 153, 141);
    font-size: 60px;
    margin-top: 100px;
    font-weight: 100;
    font-family: serif;
}

#home-page-bottom-text-two {
    color: rgb(26, 153, 141);
    font-size: 30px;
    margin-top: 100px;
    padding-bottom: 30px;
    font-weight: 100;
    font-family: serif;
}

#home-consult-button {
    background-color: rgb(26, 153, 141);
    color: white;
    font-size: large;
    border: none;
    padding: 20px;
    cursor: pointer;
    margin-bottom: 120px;
}

@media screen and (max-width: 768px) {
    #home-header #logo {
            margin-top: 40px;
            width: 80%;
            margin-left: 5%;
        }
    
        #home-header #home-subtitle {
            color: rgb(26, 153, 141);
            font-size: 20px;
            margin-top: 10px;
            /* padding: 10px; */
            font-weight: 100;
            font-family: serif;
            width: 80%;

        }
    
    
    
    
    
    
    
        #home-services-header {
            color: rgb(26, 153, 141);
            font-size: 30px;
            margin-top: 80px;
            font-family: serif;
            width: 100vw;
        }
    
        #services-home-container {
            display: flex;
            flex-direction: column;
            width: 100vw;
        }
    
        #services-home-container p {
            text-align: center;
        }
    
        .services-block {
            padding: 0px;
            /* margin-bottom: 40px; */
            font-family: serif;
            width: 100vw;
            /* background-color: pink; */
            /* margin-left: 10px; */
        }
    
        .services-block h4 {
            font-size: 25px;
            color: rgb(26, 153, 141);
            width: 100%;
        }
    
        .services-block p {
            font-size: 22px;
            text-align: left;
            width: 90%;
            margin-left: 5%;
        }
    
        #home-learn-more-button {
            background-color: rgb(26, 153, 141);
            color: white;
            font-size: large;
            border: none;
            padding: 20px;
            cursor: pointer;
            margin-bottom: 120px;
        }
    
    
    
        .image-container {
            position: relative;
            width: 100%;
            max-width: 100vw;
            height: 300px;
            /* Set the desired height */
            overflow: hidden;
            display: none;
        }
    
        .image-container img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            /* Crop the image to cover the container */
            display: block;
        }
    
        .home-photo-credit {
            position: absolute;
            bottom: 0;
            left: 0;
            color: white;
            font-size: x-small;
            width: 100%;
        }
    
        .home-photo-credit * {
            color: white;
            font-size: x-small;
        }
    
        .quote-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            color: #fff;
            width: 80%;
        }
    
        .quote-text {
            font-size: 25px;
            font-weight: bold;
            text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
        }
    
        .quote-author {
            margin-top: 10px;
            font-size: 25px;
            font-style: italic;
            text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
        }
    
    
        #home-page-bottom-text-one {
            color: rgb(26, 153, 141);
            font-size: 60px;
            margin-top: 100px;
            font-weight: 100;
            font-family: serif;
        }
    
        #home-page-bottom-text-two {
            color: rgb(26, 153, 141);
            font-size: 30px;
            margin-top: 100px;
            padding-bottom: 30px;
            font-weight: 100;
            font-family: serif;
        }
    
        #home-consult-button {
            background-color: rgb(26, 153, 141);
            color: white;
            font-size: large;
            border: none;
            padding: 20px;
            cursor: pointer;
            margin-bottom: 120px;
        }
}