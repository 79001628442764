/* ContactForm.css */

/* Form container */
form {
    display: flex;
    flex-direction: column;
    margin: 20px;
    text-align: center;
}

label {
    text-align: left;
}

#name-container {
    width: 100%;
    display: flex;
    justify-content: left;
}

/* Input containers */
.first-name-container,
.last-name-container {
    width: 50%;
    margin-bottom: 20px;
    margin-left: 20px;
}

.email-container {
    margin-bottom: 20px;
    margin-left: 20px;
    width: calc(100% - 20px);
}

.message-container {
    margin-bottom: 20px;
    margin-left: 20px;
    width: calc(100% - 20px);
    height: 90px;
}


/* Input fields */
.name-input,
.email-input {
    width: 100%;
    border: 1px solid black;
    border-radius: 2px;
    color: black;
    height: 30px;
    border-radius: 10px;
    font-size: large;
}

.message-input {
    width: 100%;
    border: 1px solid black;
    border-radius: 2px;
    color: black;
    height: 60px;
    border-radius: 10px;
    font-size: large;
}

/* Submit button */
.submit-button {
    background-color: rgb(26, 153, 141);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;
    width: 200px;
    align-items: center;
    margin: auto;
}

/* Submit button on hover */
.submit-button:hover {
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5); */
}



/* Checkbox labels */
.services-container label {
    display: block;
    margin-bottom: 5px;
    margin-left: 30px;
    /* text-align: left; */
}

/* Checkbox input */
.services-container input[type="checkbox"] {
    margin-right: 5px;
    appearance: none;
}

/* Style the custom checkbox */
.services-container input[type="checkbox"]::before {
  content: "";
  display: inline-block;
  width: 16px; /* Adjust the checkbox size as needed */
  height: 16px; /* Adjust the checkbox size as needed */
  border: 1px solid #333; /* Checkbox border color */
  background-color: white; /* Checkbox background color */
  border-radius: 1px; /* Checkbox border radius */
  vertical-align: middle;
  cursor: pointer;
}

/* Style the checked custom checkbox */
.services-container input[type="checkbox"]:checked::before {
  background-color: rgb(26, 153, 141); /* Color when checked */
  border: 1px solid rgb(26, 153, 141); /* Border color when checked */
}

.recaptcha-container {
    margin: auto;
    margin-bottom: 30px;
}


@media screen and (max-width: 768px) {
    /* Form container */
    form {
        display: flex;
        flex-direction: column;
        /* margin: 20px; */
        text-align: center;
        /* background-color: pink; */
    }

    label {
        text-align: left;
    }

    #name-container {
        width: 100%;
        display: flex;
        justify-content: left;
    }

    /* Input containers */
    .first-name-container,
    .last-name-container {
        width: 50%;
        margin-bottom: 20px;
        margin-left: 20px;
    }

    .email-container {
        margin-bottom: 20px;
        margin-left: 20px;
        width: calc(100% - 20px);
    }

    .message-container {
        margin-bottom: 20px;
        margin-left: 20px;
        width: calc(100% - 20px);
        height: 90px;
    }


    /* Input fields */
    .name-input,
    .email-input {
        width: 100%;
        border: 1px solid black;
        border-radius: 2px;
        color: black;
        height: 30px;
        border-radius: 10px;
        font-size: large;
    }

    .message-input {
        width: 100%;
        border: 1px solid black;
        border-radius: 2px;
        color: black;
        height: 60px;
        border-radius: 10px;
        font-size: large;
    }

    /* Submit button */
    .submit-button {
        background-color: rgb(26, 153, 141);
        color: white;
        border: none;
        border-radius: 4px;
        /* padding: 10px 20px; */
        cursor: pointer;
        font-weight: bold;
        width: 60%;
        margin-left: 20%;
        align-items: center;
        /* margin: auto; */
    }

    /* Submit button on hover */
    .submit-button:hover {
        /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5); */
    }



    /* Checkbox labels */
    .services-container label {
        display: block;
        margin-bottom: 5px;
        margin-left: 30px;
        /* text-align: left; */
    }

    /* Checkbox input */
    .services-container input[type="checkbox"] {
        margin-right: 5px;
        appearance: none;
    }

    /* Style the custom checkbox */
    .services-container input[type="checkbox"]::before {
        content: "";
        display: inline-block;
        width: 16px;
        /* Adjust the checkbox size as needed */
        height: 16px;
        /* Adjust the checkbox size as needed */
        border: 1px solid #333;
        /* Checkbox border color */
        background-color: white;
        /* Checkbox background color */
        border-radius: 1px;
        /* Checkbox border radius */
        vertical-align: middle;
        cursor: pointer;
    }

    /* Style the checked custom checkbox */
    .services-container input[type="checkbox"]:checked::before {
        background-color: rgb(26, 153, 141);
        /* Color when checked */
        border: 1px solid rgb(26, 153, 141);
        /* Border color when checked */
    }

    .recaptcha-container {
    margin: auto;
    margin-bottom: 20px;
}
}
